<template>
  <div class="statcard-wrapper" >
    <Card :class="{'statcard-selected': selected}">
      <template #content >
        <div class="statcard-content grid" >
          <div class="h-full col-6 flex flex-column justify-content-between">
            <div>
              <h2 class="m-0">{{ title }}</h2>
              <span class="subtitle">{{ subtitle }}</span>
            </div>
            <div class="day-information flex flex-column justify-content-end">
              <h1 class="m-0 card-amount">
                {{LastFormattedAmount}}
              </h1>
              <span class="flex align-items-center">
                <img v-if="lastPercentageDiff >= 0" class="stonks-icon" src="@/assets/images/green-stonks.svg"/>
                <img v-else class="stonks-icon" src="@/assets/images/red-stonks.svg"/>
                <span class="information" :class="{'green-text': lastPercentageDiff >= 0, 'red-text': lastPercentageDiff < 0 }">{{title === 'Tickets' ? parseFloat(formatPrice(lastAmountDiff).replace('$', '')).toFixed(0) : formatPrice(lastAmountDiff)}}</span>
              </span>
              <span class="flex align-items-center">vs previous month</span>
              <small class="flex align-items-center"><div class="top-outer-circle"> <div class="top-inner-circle"></div></div> = current month </small>
            </div>
          </div>
          <div class="h-full col-6 flex flex-column justify-content-between">
            <div class="date-wrapper flex align-items-center justify-content-end">
              <span class="date">{{ formattedDateRange }}</span>
            </div>
            <div class="graph-container justify-content-end md:justify-content-center xl:justify-content-end">
              <div v-for="(month, index) in graphAmountArray" :key="month.amount" class="graph-item-wrapper" v-tooltip.left="getTooltip(index)">
                  <div class="graph-item" :class="{'graph-last-item': index === graphAmountArray.length -1, 'green-bg': lastPercentageDiff >= 0, 'red-bg': lastPercentageDiff < 0 }" :style="{'height': `${month.percentageFromMax}%`, 'min-height': '3px'}">
                    <div v-if="month.year === currentYear && month.month === currentMonth" class="top-outer-circle">
                      <div class="top-inner-circle"></div>
                    </div>
                    <Badge
                      v-if="index === graphAmountArray.length -1"
                      class="top-badge"
                      :class="{'green-bg': lastPercentageDiff >= 0, 'red-bg': lastPercentageDiff < 0 }"
                      :value="getBadgeValue(lastAmountDiff)">
                    </Badge>
                  </div>
              </div>
            </div>
          </div>
        </div>
      </template>
    </Card>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import Card from "primevue/card";
import Badge from "primevue/badge";
import Tooltip from "primevue/tooltip";
import Utils from '@/utils/utils';
import { mapGetters } from "vuex";
export default defineComponent({
  name: "StatCard",
  components: {
    Card,
    Badge,
  },
  directives: {
    tooltip: Tooltip,
  },
  props: {
    selected: Boolean,
    title: String,
    subtitle: String,
    getter: String,
    setter: String,
    amountArray: {
      default: (): Array<number> => [],
    },
  },
  data() {
    return {
      higherAmountReference: 1,
      lastPercentageDiff: 0,
      lastAmountDiff: 0,
      dataArray: [] as any,
    }
    
  },
  computed: {
    ...mapGetters({
      getCustomer: "customer/getCust",
    }),
    graphAmountArray(): any {
      if(this.dataArray) {
        if(this.dataArray.length != 0) {
          return this.dataArray.map((month: any) => {
            return {
              amount: month.amount,
              count: month.count,
              month: month.month,
              year: month.year,
              percentageFromMax: parseFloat(month.amount) * 100 / this.higherAmountReference, 
            }
          });
        }
      }
      
      return 0;
    },
    LastFormattedAmount() {
      if(this.dataArray) {
        if(this.dataArray[11]) {
          let amount = this.dataArray[11].amount
          if(amount.includes(".")) 
          {
            amount = this.formatPrice(amount)
          }
          return amount
        }
      }
      return ""
    },
    currentMonth() {
      const date = new Date()
      return (date.getMonth() + 1).toString()
    },
    currentYear() {
      const date = new Date()
      return date.getFullYear().toString()
    },
    formattedDateRange() {
      if(this.dataArray) {
        if(this.dataArray[11]) {
          let endDate = new Date(parseInt(this.dataArray[11].year),parseInt(this.dataArray[11].month), 0);
          const endMonth = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(endDate);
          let endDay = endDate.getDate()
          if(endDate.getMonth() + 1 === parseInt(this.currentMonth) && endDate.getFullYear() === parseInt(this.currentYear)) {
            endDay = new Date().getDate();
          } 
          const endYear = endDate.getFullYear();
          let startDate = endDate 
          startDate.setMonth(endDate.getMonth() - 11)
          const startMonth =  new Intl.DateTimeFormat('en-US', {month: 'long'}).format(startDate)
          const startDay = 1
          const startYear = endDate.getFullYear();
          return `${startMonth} ${startDay}, ${startYear} - ${endMonth} ${endDay}, ${endYear}`;
        }
      }
      return ""
    }
  },
  watch: {
    amountArray() {
      this.dataArray = []
      setTimeout(() => {
        this.dataArray = JSON.parse(JSON.stringify(this.amountArray))
        this.getHigherAmountReference();
        this.getLastPercentageDiff();
      }, 15)
    }
  },
  methods: {
    getBadgeValue(lastAmountDiff: number) {
      let price = this.lastPercentageDiff > 0 ? '+' : ''
      price += this.formatPrice(lastAmountDiff)
      return this.title !== 'Tickets' ? price: price.replace('$', '') + ''
    },
    getTooltip(index: number) {
      const currentHover = this.graphAmountArray[index]
      
      const month = new Intl.DateTimeFormat('en-US', {month: 'long'}).format(new Date().setMonth(parseInt(currentHover.month) - 1)) 
      const year = currentHover.year
      let amount = currentHover.amount
      
      if(amount.includes(".")) {
        amount = this.formatPrice(amount)
      }

      const count = currentHover.count
      let sentence =  month + " " + year + "\n" + amount
      if (this.title === "Tickets") {
        sentence += " " + this.title
      } else {
        sentence += "\n" + count + " "+ this.title
      }
      return sentence
    },
    getHigherAmountReference() {
      let amountArray = [] as any
      if(this.dataArray) {
        this.dataArray.forEach((month: any) => {
          amountArray.push(month.amount)
        });
      }
      this.higherAmountReference = Math.max(...amountArray);
    },
    getLastPercentageDiff() {
      if ( this.dataArray && this.dataArray[this.dataArray.length - 1] && this.dataArray[this.dataArray.length - 2]) {
        this.lastPercentageDiff =  Math.round((parseFloat(this.dataArray[this.dataArray.length - 1].amount) * 100 / parseFloat(this.dataArray[this.dataArray.length - 2].amount)) - 100) || 0;
        this.lastAmountDiff = parseFloat(this.dataArray[this.dataArray.length - 1].amount) - parseFloat(this.dataArray[this.dataArray.length - 2].amount);
      }
    },
    formatPrice(amount: number): string {
      return Utils.formatPrice(amount);
    },
  },
  async created() {
    this.dataArray = JSON.parse(JSON.stringify(this.amountArray))
    this.getHigherAmountReference();
    this.getLastPercentageDiff();
    
  }
});
</script>

<style scoped lang="scss">
  .statcard-wrapper {
    .statcard-selected {
      border-bottom: 3px solid #0089bd;
    }
    .statcard-content{
      height: 195px;
      padding-bottom: 8px;
      .subtitle {
        color: #8f8f8f;
        font-size: 10px;
        font-weight: bold;
      }
      .card-amount {
        font-weight: 400;
        font-size: 36px;
        color: #555555;
      }
      .day-information {
        color: #b5b5b5;
        font-size: 13px;
        font-weight: 600;
        .stonks-icon {
          height: 8px;
          width: 13px;
        }
        .information {
          margin: 0 3px
        }
      }
      
      .green-text {
        color: #53bd00;
      }
      .red-text {
        color: #ff0000;
      }

      .date-wrapper {
        i {
          padding: 5px;
          border-radius: 4px;
          background-color: #c9c7c7;
        }
        .date {
          color: #8f8f8f;
          font-weight: bold;
          font-size: 10px;
          margin: 0 0 0 0;
        }
      }

      .top-outer-circle {
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;
              top: -1px;
              right: 3px;
              border-radius: 50%;
              height: 11px;
              width: 11px;
              background-color: white;
              @media screen and (min-width: 576px) and (max-width: 1200px) {
                top: -3px;
                right: 1px;
                height: 15px;
                width: 15px;
              }
              .top-inner-circle {
                height: 7px;
                width: 7px;
                background-color: #f26722;
                border-radius: 50%;
                @media screen and (min-width: 576px) and (max-width: 1200px) {
                  height: 9px;
                  width: 9px;
                }
              }
            }
      
      .graph-container {
        display: flex;
        height: 70%;
        .graph-item-wrapper {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          width: 5px;
          // Styles only for tablet view
          @media screen and (min-width: 576px) and (max-width: 1200px) {
            width: 12px;
          }
          margin: 0 5px 0 4px;

          .green-bg {
            background-color: #53bd00;
          }
          .red-bg {
            background-color: #ff0000;
          }
          &:hover {
              .graph-item {
                background-color: #616161;
                border: none;
              }
            }
          .graph-item {
            background-color: #d9d9d9;
            border-radius: 2.5px;
            @media screen and (min-width: 576px) and (max-width: 1200px) {
              border-radius: 5.5px 5.5px 2.5px 2.5px;
            }
            &.graph-last-item {
              &.green-bg {
                background-color: #53bd00;
              }
              &.red-bg {
                background-color: #ff0000;
              }
            }
            .top-badge {
              position: relative;
              top: -40px;
              right: 22px;
            }
          }
        }
      }
    }

  }
</style>