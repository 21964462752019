<template>
  <div>
    <FieldSet legend="Customer" :toggleable="true">
      <div class="grid">
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <Dropdown
              id="companies"
              v-model="selectedCust"
              :options="this.getCustomers"
              optionValue="cust_id"
              optionLabel="cust_name"
              @change="changeCustomer($event)"
            />
            <label for="companies">Current Company</label> <br />
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label" >
            <div for="address"><strong>Address</strong></div> 
            <span  v-for="(address, index) in customerAddress" :key="index">
              {{ address }} <br />
            </span>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <div for="address"><strong>Phone</strong></div> 
            <a :href="customer.phone" >
              {{ customer.phone }} 
            </a>
          </span>
        </div>
      </div>
    </FieldSet>
    &nbsp;
    <FieldSet legend="Contact" :toggleable="true">
      <div class="grid">
        <div class="field col-12 mt-2">
          <span class="p-float-label">
            <InputText id="fn" v-model="contact.first_name" />
            <label for="fn">First Name</label>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <InputText id="ln" v-model="contact.last_name" />
            <label for="ln">Last Name</label>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <InputText id="addr" v-model="this.address" />
            <label for="addr">Address</label>
          </span>
        </div>
        <div
          class="field col-12"
          v-for="(email, index) in contact.email_address_items"
          :key="email.email_address"
        >
          <span class="p-float-label" v-if="index == 0">
            <InputText id="email" v-model="this.email" />
            <label for="email">Primary Email</label>
            <small class="p-error" v-if="this.email == ''"
              >Email is required</small
            >
          </span>
          <span class="p-float-label" v-if="index != 0">
            <InputText id="email" v-model="email.email_address" />
            <label for="email">Email Address {{ index + 1 }}</label>
          </span>
        </div>
        <div class="field col-12" v-for="(telephone, index) in contact.telephone"  :key="index">
          <span class="p-float-label">
            <InputText id="tel" v-model="telephone.telephone" />
            <label for="tel">Phone #{{index+1}}</label>
          </span>
        </div>
        <div class="field col-12">
          <span class="p-float-label">
            <InputText id="fax" v-model="contact.fax" />
            <label for="fax">Fax</label>
          </span>
        </div>
        <div class="field col-12">
          <Button
            label="Save"
            @click="saveContact()"
            icon="pi pi-save"
            iconPos="left"
            :loading="this.loading"
          />
        </div>
      </div>
    </FieldSet>
  </div>
</template>
<script lang="ts">
import { mapActions, mapGetters } from "vuex";
import Dropdown from "primevue/dropdown";
import { defineComponent } from "vue";
import User, { AddressItem } from "@/types/user";
import Customer from "@/types/customer";
import InputText from "primevue/inputtext";
import FieldSet from "primevue/fieldset";
import Button from "primevue/button";
import useVuelidate from "@vuelidate/core";
import { required, minLength } from "@vuelidate/validators";
import Utils from "@/utils/utils";

export default defineComponent({
  name: "Settings",
  components: {
    Dropdown,
    InputText,
    FieldSet,
    Button,
  },
  setup() {
    return {
      v$: useVuelidate(),
    };
  },
  data() {
    return {
      selectedCust: "",
      contact: {} as User,
      address: "",
      email: "",
      loading: false,
      customer: {} as Customer,
      custAddr: "",
      custLoading: false,
    };
  },
  validations() {
    return {
      email: { required, minLength: minLength(1) },
    };
  },
  created() {
    this.setContact();
    this.setCust();
  },
  computed: {
    ...mapGetters({
      getCustomers: "session/getCustomers",
      getUser: "session/getUser",
      getCust: "customer/getCust",
    }),
    customerAddress(): any {
      return Utils.customerShippingAddress(this.customer);
    }
  },

  methods: {
    ...mapActions({
      fetchCustomer: "customer/fetchCustomer",
      updateContact: "session/updateContact",
    }),
    changeCustomer(event: { value: any }) {
      this.fetchCustomer({
        id: event.value,
        correls: "bank_token"
      }).then(() => {
        this.setCust()
        this.$router.push('/invoices')
      });
    },
    setContact() {
      this.contact = JSON.parse(JSON.stringify({ ...this.getUser })) as User;
      if (!this.contact!.address_items) {
        this.address = "";
      } else {
        let addressStr = [] as Array<string>;

        this.contact.address_items.forEach((item: AddressItem) => {
          addressStr.push(item.address as string);
        });

        this.address = addressStr.join(" ");
      }

      this.email = this.contact.email_address_items[0].email_address;
    },
    setCust() {
      this.customer = JSON.parse(JSON.stringify(this.getCust));
      this.selectedCust = this.getCust.cust_id;
      let addressList = [] as string[];
      if (this.customer.address_items != null) {
        addressList = this.customer.address_items.map((item: AddressItem) => {
          return item.address;
        });
      }

      this.custAddr = addressList.join(" ");
    },
    /*
     * @desc checks that form is valid with the validate function
     * @desc if succesful makes a post request to save contact information
     * @desc triggered by button
     *
     */
    async saveContact() {
      this.loading = true;
      const isFormCorrect = await this.v$.$validate();
      if (isFormCorrect) {
        this.contact.email_address_items = this.emailSetup();
        const addressItems = [{ address: this.address }];
        this.contact.address_items = addressItems;
        this.updateContact({ contact: this.contact });
        this.loading = false;
      } else {
        this.loading = false;
      }
    },
    saveCust() {
      this.custLoading = true;
      const customer = this.getCust;
      const oldCust = {
        cust_id: customer.cust_id,
        name: customer.name,
        address_items: customer.address_items,
        phone: customer.phone,
      };
      let newAddr = [{ address: this.custAddr }];
      const newCust = {
        address_items: newAddr,
        phone: customer.phone,
        cust_id: this.customer.cust_id,
      };
      const payload = {
        oldCust,
        newCust,
      };
      this.updateContact({
        id: this.customer.cust_id,
        newCust,
        oldCust,
      })
        .then(() => {
          this.custLoading = false;
        })
        .catch(() => {
          this.custLoading = false;
        });
    },
    /*
     * @desc filters email array to only send email items with non empty strings
     * @returns {Array} new formatted array
     */
    emailSetup() {
      const emails = this.contact.email_address_items.filter((item) => {
        return item.email_address != "";
      });

      return emails;
    },
  },
});
</script>
