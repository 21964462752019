<template>
  <div class="mr-0" style="height: 100%">
    <template v-if="session.user && isDataLoaded">
      <Toolbar v-if="!printableView" class="justify-content-between w-full" style="height: 4.5rem; position: fixed; z-index: 100">
        <template #start>
          <div class="ml-5 flex align-items-center">
            <img class="app-logo hidden md:block"
              src="https://prodroverstorage.blob.core.windows.net/rover/Rover-Logo-2-High-1.png" />
            <div class="app-title">
              <span class="my-title font-semibold"> Portal</span>
            </div>
          </div>
        </template>

        <template #end>
          <div
            class="user-initials surface-300 border-circle flex justify-content-center align-items-center text-500 font-semibold uppercase">
            {{ userInitials }}
          </div>
          <span class="pointer text-top font-semibold text-xs" style="margin-left: 17px;" @click="displaySettings = true">
            {{ customer.customer.name }}
          </span>
          &nbsp;
          <Button class="bg-white text-600 p-0" icon="pi pi-angle-down text-xl" iconPos="right"
            @click="toggleProfileMenu" />
          <TieredMenu :model="menuitems" :popup="true" ref="menu" class="tiered pointer">
            <template #item="{ item }">
              <div>
                <a @click="item.service" style="display: block" class="p-2">
                  {{ item.label }}
                </a>
              </div>
            </template>
          </TieredMenu>
        </template>
      </Toolbar>
    </template>
    <!-- Start Settings Dialog -->
    <Sidebar v-model:visible="displaySettings" position="right">
      <h3 class="text-center">Settings</h3>
      <Settings />
    </Sidebar>
    <!-- End Settings Dialog -->
    <div class="grid m-0" style="height: 100%">



      <div v-if="!printableView" class="w-full bg-white customer-name-tabs" style="margin-top: 4.5rem !important">
        <div v-if="$route.fullPath === '/sales/edit-quote'" class="text-xl font-semibold customer-name-top pl-7">
          Edit Quote &nbsp; <Tag severity="success" value="Set as Active" rounded class="edit-quote-tag" />
        </div>
        <div v-else class="text-xl font-semibold customer-name-top">
          {{ customer.customer?.name }}
        </div>
        <TabView v-if="$route.fullPath !== '/sales/edit-quote'" :scrollable="true"
          class="customers-menu-tabview sales-menu-tabview tabview-panels-hidden top-navigation-tabview xl:hidden block">
          <TabPanel v-for="(tab) in items" :key="tab.label">
            <template #header>
              <div class="menutab-item">
                <router-link :to="tab.to" class="menutab-item-link">
                  <Button :label="tab.label" class="menutab-button" />
                </router-link>
              </div>
            </template>
          </TabPanel>
        </TabView>
        <TopStatCards v-if="$route.fullPath !== '/sales/edit-quote' && isDataLoaded" />
      </div>
      <div class="w-full">
        <router-view class="ml-0 p-0" />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { mapState } from "vuex";
import Sidebar from "primevue/sidebar";
import Button from "primevue/button";
import Toolbar from "primevue/toolbar";
import TieredMenu from "primevue/tieredmenu";
import Tag from "primevue/tag";
import store from "@/store";
import Settings from "@/components/Settings.vue";
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';
import TopStatCards from './components/StatCards/TopStatCards.vue';

export default defineComponent({
  name: "App",
  components: {
    Button,
    Toolbar,
    TieredMenu,
    Sidebar,
    Settings,
    TabView,
    TabPanel,
    TopStatCards,
    Tag,
  },
  beforeCreate() {
    store.dispatch("theme/updateTheme");

    document.title = store.getters["theme/getTitle"] + " " + "Customer Portal";
  },
  created() {
    if (screen.width < 480) {
      store.dispatch("sidemenu/setCollapsed");
    }
  },
  mounted() {
    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.addEventListener(event, getResetTimer);
    }, this);
    this.setTimers();
    //this.populateTabItems();
  },
  unmounted() {
    const getResetTimer = this.resetTimer;
    this.events.forEach((event) => {
      window.removeEventListener(event, getResetTimer);
    }, this);
    this.resetTimer();
  },
  computed: {
    ...mapState(["session", "customer", "theme", "env"]),
    
    userInitials(): string {
      const splitName = this.customer.customer?.name.split(' ');
      return splitName[0].charAt(0) + splitName[0].charAt(1);
    },
    isDataLoaded(): boolean {
      return store.getters["session/getdataLoaded"];
    },
    printableView(): boolean {
      return this.$route.path.includes("printable-view");
    },
  },

  watch: {
    isDataLoaded(newVal) {
      // Check if the new value of isDataLoaded is true
      if (newVal) {
        this.populateTabItems();
      }
    }
  },

  methods: {
    logout() {
      store.dispatch("session/logout");
    },
    toggleProfileMenu(event: any) {
      (this.$refs.menu as any).toggle(event);
    },
    settings() {
      this.displaySettings = true;
    },
    setTimers() {
      this.LogoutTimer = setTimeout(this.logout, 30 * 60 * 1000);
    },
    resetTimer() {
      clearTimeout(this.LogoutTimer);
      this.setTimers();
    },
    populateTabItems(){
      if(this.env.modules.includes('Sales') || this.env.modules.length == 0){
        this.items.push({ label: "Sales ", icon: "pi pi-fw pi-dollar", to: "/sales" });
      }
      if(this.env.modules.includes('Tickets') || this.env.modules.length == 0){
        this.items.push( { label: "Tickets ", icon: "pi pi-fw pi-money-bill", to: "/tickets" },);
      }
      if(this.env.modules.includes('Invoices') || this.env.modules.length == 0){
        this.items.push( { label: "Invoices", icon: "pi pi-fw pi-folder", to: "/invoices",},);
      }
    },
  },
  data() {
    return {
      events: ["click", "mousemove", "mousedown", "scroll", "keypress", "load"],
      LogoutTimer: 0,
      menuitems: [
        {
          label: "Log Out",
          icon: "pi pi-calendar",
          service: this.logout,
        },
        {
          label: "Settings",
          icon: "pi pi-cog",
          service: this.settings,
        },
      ],
      showMenu: false,
      displaySettings: false,
      items: [] as any,      
    };
  },
});
</script>

<style scoped lang="scss">
.app-title {
  // height: 2.5rem; 
  margin-left: 0.5rem;
  font-size: 24px;
  color: #7E7F81;
}

.user-initials {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 15px;
}

.customer-name-top {
  display: flex;
  height: 52px;
  align-items: center;
  padding-left: 60px;
  background-color: #f3f3f3;
  border-top: 1px solid #e6e6e6;
  border-bottom: 1px solid #e6e6e6;
}

.top-navigation-tabview {
  padding-left: 60px;
  background-color: var(--highlighted-color);
}
</style>