<template>
  <div class="w-full grid mr-0 justify-content-center pt-3 top-statcards-wrapper">
    <div class="col-11 grid justify-content-start">
      <div v-if="env.modules.includes('Invoices') || env.modules.length == 0" class="xl:col-4 xl:block col-12" :class="{'block': $route.fullPath?.toString().includes('invoices')}">
        <router-link to="/invoices" class="no-underline">
          <StatCard :selected="$route.fullPath?.toString().includes('invoices')" title="Invoices" subtitle="Amount Due" getter="invoice/getMonthlyData" setter="invoice/fetchMonthlyData" :amountArray="invoicesMonthlyData" />
        </router-link>
      </div>
      <div v-if="env.modules.includes('Quotes')" class="xl:col-4 xl:block col-12" :class="{'block': $route.fullPath?.toString().includes('tickets')}">
        <router-link to="/quotes" class="no-underline">
          <StatCard :selected="$route.fullPath?.toString().includes('quotes')" title="Quotes" subtitle="Quotes Created" getter="sales/getQuotesMonthlyData" setter="sales/fetchQuotesMonthlyData" :amountArray="quotesData" />
        </router-link>
      </div>
      <div v-if="env.modules.includes('Tickets') || env.modules.length == 0" class="xl:col-4 xl:block col-12" :class="{'block': $route.fullPath?.toString().includes('tickets')}">
        <router-link to="/tickets" class="no-underline">
          <StatCard :selected="$route.fullPath?.toString().includes('tickets')" title="Tickets" subtitle="Tickets Created" getter="billing/getMonthlyData" setter="billing/fetchMonthlyData" :amountArray="ticketsMonthlyData" />
        </router-link>
      </div>
      <div v-if="env.modules.includes('Sales') || env.modules.length == 0" class="xl:col-4 xl:block col-12" :class="{'block': $route.fullPath?.toString().includes('sales')}">
        <router-link to="/sales" class="no-underline">
          <StatCard :selected="$route.fullPath?.toString().includes('sales')" title="Orders" subtitle="Orders Placed" getter="sales/getSoMonthlyData" setter="sales/fetchSoMonthlyData" :amountArray="ordersMonthlyData" /> 
        </router-link>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import StatCard from './StatCard.vue';
import { mapState, mapGetters } from "vuex";
import store from "@/store";

export default defineComponent({
  components: {
    StatCard,
  },
  data() {
    return {
      selectedStatCard: '/orders',
      items: [
        { label: "Invoices", to: "/invoices" },
        { label: "Tickets ", to: "/tickets" },
        { label: "Sales ", to: "/sales" },
        { label: "Quotes ", to: "/quotes" },
      ],
      setters: [
        { name: 'Sales', setter: 'sales/fetchSoMonthlyData' }, 
        { name: 'Invoices', setter: 'invoice/fetchMonthlyData' }, 
        { name: 'Tickets', setter: 'billing/fetchMonthlyData' }, 
        // { name: 'Quotes', setter: 'sales/fetchQuotesMonthlyData'}
      ] 
    }
  },
  computed: {
    ...mapState(["customer", "env"]),
    ...mapGetters({
      customerData: "customer/getCust",
      ordersMonthlyData: "sales/getSoMonthlyData",
      invoicesMonthlyData: "invoice/getMonthlyData",
      ticketsMonthlyData: "billing/getMonthlyData",
    })
  },
  methods: {
    async fetchMonthlyData() {
      let date = new Date()
      date.setMonth(date.getMonth() - 11)
      this.setters.forEach(async ({name, setter}) => {
        if (this.env.modules.includes(name) || this.env.modules.length == 0) {
          await store.dispatch(`${setter}`, {month: date.getMonth() + 1, year: date.getFullYear(), cust: this.customerData.cust_id})
        }
        
      })
    }
  },
  created() {
    this.fetchMonthlyData()
  },
  watch: {
    customerData() {
      this.fetchMonthlyData()
    }
  }
})
</script>

<style scoped lang="scss">
  .top-statcards-wrapper {
    background-color: #fafafa;
  }
</style>